<template>
  <div>
    <div class="unit-topbar">
      <div class="topbar-container">
        <div class="topbar-container__title">
          <span>{{ testTypeName }}</span> |
          <span>{{ courseName }}</span> |
          <template v-if="unitName != ''">
            <span>{{ unitName }}</span> |
          </template>
          <span>（共{{ timuNum }}道题）</span>
        </div>
        <div class="topbar-container__sidebar">
            <div class="dialog-title__persons">
                <img :src="avatarImg" alt="" style="border-radius: 50%">
                <span>{{ userName }}</span>
            </div>
          <el-button class="submit-btn" @click="actionSave" v-if="kaoshi_status == 0">
            <span>交</span><span>卷</span>
          </el-button>
          <a href="javascript:;" @click="closeDialog">返回</a>
        </div>
      </div>
    </div>
      <div class="left-timer online-time">
          <div class="online-time__title">
              <img :src="cloakImg" alt="">
              <span style="color: #1CA3A3">在线时间</span>
          </div>
          <span class="left-time" style="color: #1CA3A3">{{ jsq_times }}</span>
      </div>
      <div class="timer online-time">
          <div class="online-time__title">
              <img :src="cloakSrc" alt="">
              <span>剩余时间</span>
          </div>
          <span class="right-time">{{ djs_msg }}</span>
      </div>
    <div class="unit">
      <div class="test-result" v-if="kaoshi_status == 1">
        <div class="result-info">
          <span class="info-text">测试结果</span>
          <div class="info-img">
            <div class="achievement">
              <span class="test-text">测试成绩</span>
              <span class="score">{{ scorse }}</span><span>分</span>
              <div class="achievement-questions">
                <span class="right-check">答对<span>{{ rightCount }}</span>题</span>
                <span class="right-error">答错<span>{{ errorCount }}</span>题</span>
              </div>
              <div class="time-box">
                <span class="used-time">使用时间：{{ yongshi_time }}</span>
              </div>
            </div>
            <div class="proposal">
              <span class="test-text">学习建议</span>
              <div class="gain">
                <img src="@/assets/images/study/gold-logo.png" alt="" />
                <span class="gold">{{ baoshi_num }}</span>
              </div>
              <div class="tuijian">
                <span class="test-text text">推荐</span>
                <el-button class="error-word" @click="cuociGonggu">错词巩固</el-button>
                <el-button class="test-again" @click="againTest">再次测试</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="test-questions">
          <div class="questions-btn">
            <el-button :class="['all', isShowType == 0 ? 'eCheck' : '']" @click="showDaan(0)">错误试题</el-button>
            <el-button :class="['all', isShowType == 1 ? 'eCheck' : '']" @click="showDaan(1)">全部试题</el-button>
          </div>
          <div class="questions-right">
            <span class="right-check" style="display:none">答对<span>{{ rightCount }}</span>题</span>
            <span class="right-error" style="display:none">答错<span>{{ errorCount }}</span>题</span>
            <el-button class="afresh" @click="againTest">重新测试</el-button>
          </div>
        </div>
      </div>
      <div class="unit-test">
        <div class="unit-test-item">
          <div class="item-title">
            <span>【中译英（Tab跳转下一题）】</span>
          </div>
          <template v-if="kaoshi_status == 0">
            <div class="item-questions item-content">
              <div class="item-questions-info info-container answer-container" v-for="(item, index) in yyz_lists_daan"
                :key="index">
                <div class="answer">
                  <div class="answer-chinese">
                    <span>{{index+1}}.{{ item.analysis }}</span>
                    <!-- 幼儿图片 -->
                    <img :src="audioUrl + item.img_link" alt="" @error="imgError" v-if="nianji_level==1">
                  </div>
                </div>
                <div class="answer-input">
                  <el-input v-model="item.daan" placeholder="请输入英文" type="text"></el-input>
                </div>
              </div>
            </div>
          </template>

          <template v-if="kaoshi_status == 1">
            <div class="item-questions item-content">
              <div class="item-questions-info info-container answer-container" v-for="(item, index) in yyz_lists_daan"
                :key="index" v-if="item.is_show == 1">
                <div class="answer">
                  <div class="answer-chinese">
                    <span>{{index+1}}.{{ item.analysis }}</span>
                    <!-- 幼儿图片 -->
                    <img :src="audioUrl + item.img_link" alt="" @error="imgError" v-if="nianji_level==1">
                  </div>
                  <div class="" :class="['answer-icon', item.is_right == 0 ? 'aError' : '']">
                    <span class="answer-icon__text" style="vertical-align: 0; margin-left: 8px"
                      v-if="item.is_right == 0">{{ item.name }}</span>
                  </div>
                </div>
                <div class="answer-input">
                  <el-input v-model="item.daan" placeholder="请输入英文" type="text"></el-input>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="submit">
          <el-button @click="closeDialog" v-if="kaoshi_status == 1"><span>关</span><span>闭</span></el-button>
          <el-button @click="actionSave" v-if="kaoshi_status == 0"><span>交</span><span>卷</span></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { imgError, nianjiLevel } from "@/utils/utils";
export default {
  name: "unitTest",
  props: {
    testData: { type: Object },
    courseId: { type: Number, default: 0 },
    danyuanId: { type: Number, default: 0 },
    danyuanIdStr: { type: String, default: "" },
    courseName: { type: String },
    unitName: { type: String,default:'' },
    nianjiId: { type: Number, default: 0 },
    default: "",
    testTypeName: { type: String, default: "" },
    testType: { type: Number, default: 1 },
    audioUrl: { type: String, default: "" },
  },
  data() {
    return {
        avatarImg: '',
        userName: '',
        jsq_times: "00.00.00",
        cloakImg: require('@/assets/images/study/icons8-stopwatch.gif'),
        cloakSrc: require('@/assets/images/study/icons8-notification.gif'),
      dialogVisible: false,
      fullscreen: true,
      leter_serial: ["A", "B", "C", "D"],
      yyz_lists_daan: [],
      kaoshi_status: 0, //考试状态，0考试中，1考试结束
      scorse: 0,
      type: 4, //1记忆，2词听，3听写，4默写
      rightNum: 0, //正确多少道题
      isShowType: 0, //0是否显示错误,1显示全部
      maxtime: 0, //秒
      djs_msg: "",
      djs_count: 0,
      djs_obj: "",
      djs_state: 1, //倒计时状态,1运行，0删除
      djs_stop: 0, //1暂停，0进行中
      yongshi: 0,
      yongshi_time: "",
      action_status: 1, //防止提交多次
      chooseAudios: {
        jxnl: require("../../assets/audio/jxnl.mp3"),
        nzb: require("../../assets/audio/nzb.mp3"),
      },
      baoshi_num: 0,
      nianji_level: 0,//年纪等级，0默认，1少儿
      imgError: '',
    };
  },
  created() {
      this.getUserInfo()
      this.jsq()
    this.imgError = imgError;
    this.nianji_level = nianjiLevel(this.user, { nianji_id: this.nianjiId });
    console.log("nianji_level:", this.nianji_level);

    this.initData();
    //console.log("testType" + this.testType);
    this.setDjs();
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      user: (state) => state.user.user,
    }),
    rightCount() {
      let right_count = 0;
      this.yyz_lists_daan.forEach((item, index) => {
        if (item.is_right == 1) {
          right_count++;
        }
      });
      return right_count;
    },
    errorCount() {
      let error_count = 0;
      this.yyz_lists_daan.forEach((item, index) => {
        if (item.is_right == 0) {
          error_count++;
        }
      });
      return error_count;
    },
    errorLists() {
      let errors = [];
      this.yyz_lists_daan.forEach((item, index) => {
        if (item.is_right == 0) {
          errors.push(item.id);
        }
      });
      return errors;
    },
    rightLists() {
      let right = [];
      this.yyz_lists_daan.forEach((item, index) => {
        if (item.is_right == 1) {
          right.push(item.id);
        }
      });
      return right;
    },
    timuNum() {
      return this.yyz_lists_daan.length;
    },
  },
  methods: {//封装一个处理单位数字的函数
      showNum(num) {
          if (num < 10) {
              return "0" + num;
          }
          return num;
      },
      jsq() {
          //点击开始建 开始计数
          let that = this;
          var uid = this.user.id;
          //console.log("uid:" + uid);
          var s = window.sessionStorage.getItem("jqx_count" + uid);
          var count = 0;
          if (s) {
              //console.log("sessionStorage:" + s);
              count = s;
          }
          var timer = null; //timer变量记录定时器setInterval的返回值
          timer = setInterval(function () {
              count++;
              window.sessionStorage.setItem("jqx_count" + uid, count);
              //console.log(count);
              // 需要改变页面上时分秒的值
              //console.log($("id_S"));
              var times_str = "";
              times_str += that.showNum(parseInt(count / 60 / 60)) + ":";
              times_str += that.showNum(parseInt(count / 60) % 60) + ":";
              times_str += that.showNum(count % 60);
              //console.log(times_str);
              that.jsq_times = times_str;
          }, 1000);
      },
      getUserInfo() {
          let than = this;
          this.loading = true;
          //let danyuan_ids = this.danyuanCheckStr;
          let params = {
              token: this.token,
          };
          //console.log(params);
          this.axios
                  .post("/api/user/get_user_info", params)
                  .then((res) => {
                      let data = res.data;
                      //console.log("get_user_info");
                      console.log(data, "用户信息");
                      this.avatarImg = data.data.avatar
                      this.userName = data.data.truename
                      if (data.status_code == 200) {
                          //console.log(data.data);
                          this.$store.commit("setUser", data.data);
                      } else if (res.data.status_code == 202) {
                      } else {
                          //this.$message(res.data.msg)
                      }
                  })
                  .catch((err) => {
                      //this.loading = false
                  });
      },
    initData(data = "") {
      let test_data = [];
      if (data == "") {
        test_data = this.testData;
      } else {
        test_data = data;
      }
      let danci_length = 0;
      this.yyz_lists_daan = test_data.yyz_lists.map((item, index, arr) => {
        let temp_item = {
          id: item.id,
          analysis: item.analysis,
          name: item.name,
          daan: "",
          shiyi: item.shiyi,
          shiyi_dx: item.shiyi_dx,
          shiyi_zyy: item.shiyi_zyy,
          is_right: 0,
          is_show: 0,
          timu_type: "moxie",
          img_link: item.img_link
        };
        danci_length = danci_length + item.name.length;
        return temp_item;
      });

      if (this.nianji_level == 1) {
        this.maxtime = danci_length * 3 * 2;//一，二年级时间多加一倍
      } else {
        this.maxtime = danci_length * 3;
      }

      this.kaoshi_status = 0; //考试状态，0考试中，1考试结束
      this.scorse = 0;
      this.rightNum = 0; //正确多少道题
      this.isShowType = 0; //0是否显示错误,1显示全部
      this.djs_stop = 0;
      (this.action_status = 1), //防止提交多次
        this.setDjs();
    },
    chooseAudio(type = 1) {
      let audio = new Audio();
      audio.controls = false;
      let src = this.chooseAudios.nzb;
      if (type === 0) {
        src = this.chooseAudios.jxnl;
      }
      audio.src = src;
      audio.volume = 0.5;
      audio.play();
    },
    againTest() {
      //this.initData();
      this.$emit("againTest");
    },
    actionSave() {
      this.djs_stop = 1;
      this.$confirm("确定要交卷吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.djs_state = 0;
          this.clearDjs();
          this.saveData();
        })
        .catch(() => {
          this.djs_stop = 0;
        });
    },
    saveData() {
      if (this.action_status == 0) return;
      this.action_status = 0;

      for (var i = 0; i < this.yyz_lists_daan.length; i++) {
        let item = this.yyz_lists_daan[i];
        if (this.trim(item.daan) == item.name) {
          item.is_right = 1;
        } else {
          item.is_right = 0;
        }
      }

      this.scorse = Math.round(Math.floor((this.rightCount / this.timuNum) * 100));
      this.showDaan(this.isShowType);
      //this.getBaoshi();

      let data = {
        user_id:this.user.id,
        token: this.token,
        scorse: this.scorse,
        test_type: this.testType,
        dadui_num: this.rightCount,
        course_id: this.courseId,
        danyuan_id: this.danyuanId,
        danyuan_id_str: this.danyuanIdStr,
        dacuo_num: this.errorCount,
        all_words_num: this.timuNum,
        yongshi: this.yongshi,
        yongshi_time: this.yongshi_time,
        type: this.type,
        data: this.yyz_lists_daan,
        error_lists: this.unique(this.errorLists),
        right_lists: this.unique(this.rightLists),
      };
      this.axios
        .post("/api/test_center/save_test", data, {
          loading: true
        })
        .then((res) => {
          //console.log(res);
          if (res.data.status_code == 200) {
            this.baoshi_num = res.data.data.integral;
            if (this.scorse > 80) {
              this.chooseAudio(1);
            } else {
              this.chooseAudio(0);
            }
            this.kaoshi_status = 1;
            /*this.$message({
              message: "提交成功，测试完成",
              offset: 300,
              type: "success",
              duration: "3000",
            });*/
            //this.emitClose(1, 1);
          } else {
            this.action_status = 0;
            this.$message(res.data.msg);
          }
        })
        .catch((err) => {
          //this.loading = false;
          this.hideLoading();
          this.action_status = 1;
        });
    },
    trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    },
    getBaoshi() {
      let data = {
        token: this.token,
        scorse: this.scorse,
        test_type: this.testType,
        course_id: this.courseId,
        danyuan_id: this.danyuanId,
        danyuan_id_str: this.danyuanIdStr,
        type: this.type,
        get_baoshi_type: 2,
        error_lists: this.unique(this.errorLists),
        right_lists: this.unique(this.rightLists),
      };
      this.axios
        .post("/api/test_center/get_baoshi", data)
        .then((res) => {
          //console.log(res);
          if (res.data.status_code == 200) {
            this.baoshi_num = res.data.data.integral;
          } else if (res.data.status_code == 202) {
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    closeDialog() {
      if (this.kaoshi_status == 0) {
        this.djs_stop = 1;
        this.$confirm("您还没有交卷，确定要返回吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.djs_state = 0;
            this.clearDjs();
            this.$emit("close");
          })
          .catch(() => {
            this.djs_stop = 0;
          });
      } else {
        this.djs_state = 0;
        this.clearDjs();
        this.$emit("close");
      }
    },
    showDaan(index) {
      this.isShowType = index;
      for (var i = 0; i < this.yyz_lists_daan.length; i++) {
        if (this.isShowType == 0) {
          if (this.yyz_lists_daan[i].is_right != 1) {
            this.yyz_lists_daan[i].is_show = 1;
            //this.$set(this.yyz_lists_daan, i, {is_show:1});
          } else {
            this.yyz_lists_daan[i].is_show = 0;
          }
        } else if (this.isShowType == 1) {
          this.yyz_lists_daan[i].is_show = 1;
          //this.$set(this.yyz_lists_daan, i, {is_show:1});
        }
      }
      //console.log(this.yyz_lists_daan);
    },
    cuociGonggu() {
      if (this.scorse == 100) {
        this.$message({
          message: '没有错词',
          type: 'warning'
        });
        return;
      }
      let err_lists = this.unique(this.errorLists);
      this.$emit("cuociGonggu", err_lists);
      ////console.log(this.errorLists);
    },
    /**
     * [study_djs 倒计时]
     * @param  {Number} value [description]
     * @return {[type]}       [description]
     */
    setDjs(value = 0) {
      var that = this;
      that.djs_count = that.maxtime;
      if (this.djs_obj) {
        clearInterval(this.djs_obj);
      }
      if (value > 0) {
        that.djs_count = value;
      }

      //console.log("倒计时" + that.djs_count);
      that.djs_state = 1;
      let maxtime = that.djs_count;
      this.djs_obj = setInterval(function () {
        if (that.djs_state != 1) {
          clearInterval(that.djs_obj);
          that.djs_msg = "";
          return false;
        }
        if (that.djs_stop == 1) {
          return;
        }
        //console.log("maxtime" + maxtime);

        if (maxtime >= 0) {
          let hour = 0;
          let minutes = Math.floor(maxtime / 60);
          if (minutes > 60) {
            hour = Math.floor(minutes / 60);
            minutes = Math.floor(minutes % 60);
          }
          let seconds = Math.floor(maxtime % 60);
          if (hour < 10) {
            hour = "0" + hour;
          }
          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          that.djs_msg = hour + ":" + minutes + ":" + seconds;
          if (that.kaoshi_status == 0) {
            --maxtime;
            that.zj_yongshi();
          }
        } else {
          clearInterval(that.djs_obj);
          that.djs_msg = "";
          that.kaoshi_status = 1;
          that.saveData();
        }
      }, 1000);
    },
    zj_yongshi() {
      var that = this;
      that.yongshi++;
      var hour = Math.floor(that.yongshi / 3600);
      var minute = Math.floor((that.yongshi - hour * 3600) / 60);
      var second = that.yongshi - hour * 3600 - minute * 60;
      if (hour > 0) {
        that.yongshi_time = hour + "时" + minute + "分" + second + "秒";
      } else {
        that.yongshi_time = minute + "分" + second + "秒";
      }
      //console.log(that.yongshi_time);
    },
    clearDjs() {
      if (this.djs_obj) {
        clearInterval(this.djs_obj);
      }
    },
    unique(arr) {
      const seen = new Map();
      return arr.filter((a) => !seen.has(a) && seen.set(a, 1));
    },
  },
  destroyed() {
    //window.removeEventListener("keyup", this.key_up);
    //window.removeEventListener("keydown", this.key_down);
    this.clearDjs();
  }
};
</script>

<style lang="scss">
// @import "@/assets/css/ai_unit_test.scss";
.answer {
  .topBorder {
    border-top: 1px solid #ccc;
  }
}

.achievement-questions {
  position: absolute;
  bottom: 40px;
  width: 100%;
}

.achievement-questions .right-check {
  color: #2bc2a1;
  padding-right: 15px;
}

.achievement-questions .right-error {
  color: #FF3F3F;
  padding-right: 15px;
}.dialog-title__persons {
     padding: 2px 8px;
     height: 30px;
     line-height: 30px;
     font-size: 12px;
     img {
         margin-right: 5px;
         width: 20px;
         height: 20px;
         vertical-align: -4px;
     }
 }
.left-timer {
    position: fixed;
    top: 60px;
    left: 60px;
}
.online-time {
    &__title {
        span {
            font-size: 16px;
            font-weight: 400;
        }
    }
    .left-time {
        padding: 2px 10px;
        font-size: 30px;
        color: #ff6a00;
        font-weight: bold;
        background-color: #EDFEF9;
    }
    .right-time {
        padding: 2px 10px;
        background-color: #FDF4EF;
    }
    img {
        width: 40px;
        height: 40px;
    }
    &.timer {
        position: fixed;
        top: 60px;
        right: 60px;
    }
}
</style>
